namespace Portico.Configurations.Endpoints {
	import IWebServiceUrls = Portico.Models.Interfaces.IWebServiceUrls;
	import IServiceDefinition = Portico.Models.Interfaces.IServiceDefinition;
	import Inject = Portico.Decorators.Inject;
	import Service = Portico.Decorators.Service;

	export class ProductionWebServiceUrls implements IWebServiceUrls {

        public readonly legacyEnrollment = {
            url: 'https://enrollment.trialcard.com/api/v1',
            audienceId: '7a405d7824084e718c1583e01ea0bc03',
            storageKey: 'LegacyEnrollmentToken'
        } as IServiceDefinition;

		public readonly legacyCard = {
			url: 'https://card.trialcard.com/api/v1',
            audienceId: '05c5c394218f4e289f90d578434fdb55',
			storageKey: 'LegacyCardToken'
		} as IServiceDefinition;

        public readonly enterpriseProgram = {
			url: 'https://program-uat-int.trialcard.com/api/v2/',
			audienceId: 'c867230229e24d66be96025e673b3345',
			storageKey: 'ProgramToken'
        } as IServiceDefinition;

        public readonly gateway = {
            url: 'https://apigateway.trialcard.com/'
        } as IServiceDefinition;
	}

	((angular: angular.IAngularStatic): void => {
		angular.module("app")
			.constant('webServiceUrls', new ProductionWebServiceUrls() as IWebServiceUrls);
	})(angular);
}